import { EGoalType, Lang } from '../../../../models'
import { BadgeType } from '../../../../models/Badge'
import { Challenge } from '../../../../models/Challenge'
import defaultFr from '../../../lang/fr'
import { tipsFr } from './tipsContent'

const digigreenFr: Lang = {
    ...defaultFr,
    authentication: {
        title: 'Rendre mes pratiques numériques plus responsables',
        description: ['Je mesure les émissions CO2 de mes usages sur Microsoft 365 pour développer des pratiques numériques de travail durables.'],
        buttonLabel: 'Se connecter avec Microsoft 365',
    },
    presentation: {
        title: 'Rendre mes pratiques numériques plus responsables',
        description: ['Je commence par réduire mes émissions CO2'],
    },

    home: {
        goals: {
            displayAllGoals: 'Afficher la section',
            reduceDisplayedGoals: 'Réduire la section',
        },
        victory: {
            ...defaultFr.home.victory,
            title: 'Mes badges obtenus',
            badges: {
                displayAllBadges: 'Afficher la section',
                reduceDisplayedBadges: 'Réduire la section',
                awardPeriod: (from: string, to: string) => `Du ${from} au ${to}`,
                planetFriendly: {
                    title: 'Ami de la planète',
                    reducedBy: 'Réduction des émissions de',
                    comparedToLastYear: (decreaseValue: any, lastYear: any) => `${decreaseValue}% par rapport à ${lastYear}`,
                },
                drive: {
                    title: 'Super nettoyeur',
                    reducedBy: '',
                    comparedToLastYear: (removedDataMB: number) => `${removedDataMB} Mo supprimé${removedDataMB > 1 ? 's' : ''} sur mon OneDrive`,
                },
                mail: {
                    title: 'Email amincisseur',
                    reducedBy: '',
                    comparedToLastYear: (maxAttachementMB: number) => `1 semaine sans envoyer de pièce jointe de plus de ${maxAttachementMB} Mo`,
                },
                digitalCleanupDay: {
                    title: 'Digital cleanup Day',
                    reducedBy: '',
                    comparedToLastYear: (removedDataMB: number) => `${removedDataMB} Mo nettoyé${removedDataMB > 1 ? 's' : ''}`,
                },
                internAttachment: {
                    title: 'Stop aux pièces jointes en interne',
                    reducedBy: '',
                    comparedToLastYear: () => 'Aucune pièce jointe envoyée en interne cette semaine',
                },
                mailShot: {
                    title: 'Pas de publi-postage',
                    reducedBy: '',
                    comparedToLastYear: (mailShotGoal: number) => `Nombre de destinataires par email limité à ${mailShotGoal} cette semaine`,
                },
                imageHunt: {
                    title: 'Chasse aux images',
                    reducedBy: '',
                    comparedToLastYear: (deletedImagesVolume: number) => `${deletedImagesVolume} Mo d'images supprimées sur mon OneDrive`,
                },
                videoHunt: {
                    title: 'Haro sur les vidéos',
                    reducedBy: '',
                    comparedToLastYear: (deletedVideosVolume: number) => `${deletedVideosVolume} Mo de vidéos supprimées sur mon OneDrive`,
                },
            },
        },
        blog: {
            title: 'Les articles qui résonnent',
            readMore: 'Lire la suite',
        },
    },
    goal: {
        ...defaultFr.goal,
        typeInfo: {
            ...defaultFr.goal.typeInfo,
            carbonFootPrint: {
                title: 'Réduire mes émissions CO2',
                theme: 'Environnement',
            },
            disconnectionTime: {
                title: 'Assurer mes temps de déconnexion',
                theme: 'Bien être au travail',
            },
        },
        home: {
            ...defaultFr.goal.home,
            addGroupGoal: 'Ajouter un objectif pour un groupe',
            see: 'Comprendre l’évolution',
            precision: 'par rapport aux 30 jours précédents',
            groupProgress: 'Progression du groupe :',
            individualProgress: 'Ma progression',
            addindividualIndicators: 'Ajouter mes indicateurs',
            addindividualIndicatorsDescription: 'Suivez votre propre progression avec vos indicateurs',
        },
        individual: {
            ...defaultFr.goal.individual,
            button: {
                title: 'Mes perf’s',
                addIndicators: 'Mesurer mes perf’s',
                qvt: 'Mes indicateurs QVT',
            },
            planetFriendlyBadge: {
                title: 'Réduire mes émissions CO2',
                description: `Chaque mois, j’obtiens mon badge Ami de la
                 planète en réduisant mes émissions de 4% sur le mois comparé à l’année dernière.`,
                seeAllBadges: 'Consulter mes badges précédents',
                noBadge: `Chaque mois, j’obtiens mon badge Ami de la
                planète en réduisant mes émissions de 4% sur le mois comparé à l’année dernière.`,
            },
            addIndicatorsPopup: {
                title: 'Ajouter mon objectif personnel',
                description:
                    "Cette vue rassemble mes indicateurs suivant le même modèle que l’objectif collectif. Je peux suivre mes pergf's et bénéficier d’un accompagnement individualisé. En rejoignant la démarche, je serai informé des nouveaux challenges lancés par l’animateur des entités dont je suis memebre.",
                descriptionSupp: 'Je suis la seule personne à voir mes indicateurs individuels. Je peux supprimer mon compte à tout moment.',
                add: 'Ajouter',
                cancel: 'Annuler',
            }
        },
        carbonFootprint: {
            ...defaultFr.goal.carbonFootprint,
            title: 'Détail de mes émissions',
            groupetitle: 'Détail des émissions',
            measuredDataMissing: 'Données manquantes',
            correspondence: 'Cela correspond aux usages suivants :',
        },
        collective: {
            button: {
                title: 'Mes groupes',
            },
            presentation: {
                ...defaultFr.goal.collective.presentation,
                title: 'Objectif de mon équipe',
                noDescription: 'Je définis l’objectif de mon équipe.',
            },
        },
        carbonCumul: {
            ...defaultFr.goal.carbonCumul,
            titleIndividuel: 'Ma trajectoire annuelle',
            titleGroup: 'Trajectoire annuelle',
            leftTextIndividuel: 'Réduire de 4% mes émissions CO2 / an pour s’aligner avec les Accords de Paris.',
            leftTextGroup: 'Réduire de 4% nos émissions CO2 / an pour s’aligner avec les Accords de Paris.',
            carbonEmissions: (goalType: EGoalType) => {
                switch (goalType) {
                    case EGoalType.COLLECTIVE:
                        return `Emissions CO2 moyenne par utilisateur depuis le 1er janvier ${new Date().getFullYear()} :`
                    case EGoalType.INDIVIDUAL:
                        return `Mes émissions cumulées depuis le 1er janvier ${new Date().getFullYear()} :`
                    default:
                        return ''
                }
            },
            toolTip: 'sur les 30 derniers jours',
            annee: 'Année',
            goal: 'Objectif',
            decile: {
                title: {
                    myPosition: 'Ma position :',
                    info: 'Un décile regroupe un 10ième de la population totale. Il y a donc 10 déciles',
                },
                position(firstQuintil, position) {
                    return `Vous avez émis ${firstQuintil ? 'moins' : 'plus'} que ${position}% des utilisateurs de DigiGreen dans l’entreprise.`
                },
            },
        },
        cohort: {
            title: 'Ils changent leurs pratiques numériques',
            joinCohort: 'Je rejoins la démarche en ajoutant mes indicateurs personnels',
            noUserCohortMessage: 'Parce qu’on va plus loin ensemble, je mobilise mes collègues sur l’objectif d’équipe !',
        },
        weeklyCleaning: {
            title: 'Mes dernières suppressions',
            cleanPeriod: (from: string, to: string) => `Du ${from} au ${to}`,
            description: 'Nettoyer ma boite email et mon espace OneDrive',
            noCleaning: 'Rien n’a été supprimé cette semaine',
            emailDeleted: 'de emails supprimés',
            driveDeleted: 'de fichiers supprimés',
        },
        challenge: {
            ...defaultFr.goal.challenge,
            title: 'Challenge de la semaine',
            noChallenge: {
                animator: 'Pour faire progresser votre groupe dans son objectif ajouter un challenge',
                simple: 'Pas de challenge programmé',
            },
            beforeChallengeStartText: 'avant le début du challenge',
            digitalCleanupDay: {
                noDataTitle: 'Allégez votre espace numérique de travail ! ',
                noDataText: 'Pensez à nettoyer votre Onedrive et votre boîte email.',
                mails: 'd’emails supprimés',
                files: 'de fichiers supprimés',
                after: {
                    title: 'Nettoyez votre boite email et votre espace OneDrive',
                    titleGroup: 'Résultat du ',
                    groupName: 'groupe',
                    titleCompany: 'Résultat de ',
                    companyName: "l'entreprise",
                },
            },
            label: (badgeType: BadgeType) => {
                switch (badgeType) {
                    case BadgeType.PLANET_FRIENDLY:
                        return 'Planet friendly'
                    case BadgeType.MAIL:
                        return 'Email amincisseur'
                    case BadgeType.DRIVE:
                        return 'Super nettoyeur'
                    case BadgeType.DIGITAL_CLEANUP_DAY:
                        return 'Digital Cleanup'
                    case BadgeType.INTERN_ATTACHMENT:
                        return 'Stop aux pièces jointes en interne'
                    case BadgeType.MAILSHOT:
                        return 'Pas de publi-postage'
                    case BadgeType.IMAGE_HUNT:
                        return 'Chasse aux images'
                    case BadgeType.VIDEO_HUNT:
                        return 'Haro sur les vidéos'
                    default:
                        return ''
                }
            },
            description: (challenge: Challenge) => {
                switch (challenge.badgeType) {
                    case BadgeType.MAIL:
                        return `Limitez les pièces jointes à ${challenge.mailGoalSize} Mo`
                    case BadgeType.DRIVE:
                        return `Supprimer ${challenge.driveGoalSize} Mo dans mon OneDrive personnel`
                    case BadgeType.DIGITAL_CLEANUP_DAY:
                        return 'Nettoyer ma boite email et mon espace OneDrive'
                    case BadgeType.INTERN_ATTACHMENT:
                        return "N'envoyez aucune pièce jointe en interne cette semaine"
                    case BadgeType.MAILSHOT:
                        return `Limitez cette semaine le nombre maximum de destinataires à ${challenge.mailShotGoalSize} par email.`
                    case BadgeType.IMAGE_HUNT:
                        return `Supprimez cette semaine ${challenge.imageHuntGoalSize} Mo d’images dans votre OneDrive personnel`
                    case BadgeType.VIDEO_HUNT:
                        return `Supprimez cette semaine ${challenge.videosHuntGoalSize} Mo de vidéos dans votre OneDrive personnel`
                    default:
                        return ''
                }
            },
        },
    },
    webinar: {
        ...defaultFr.webinar,
        startAt: '18 septembre à 11h30',
        contentTitle: '“How to clean up your OneDrive spaces”',
        description: '',
        registerationUrl:
            'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NzdmOTEyMzUtOWZjZS00MDNhLThiOGEtZGUzZDQ3ZDE3NGVm%40thread.v2/0?context=%7b%22Tid%22%3a%223ee81190-954b-4064-8e7d-f12fd761fd39%22%2c%22Oid%22%3a%2246fa96b0-4b60-4713-8e9c-344f202cc138%22%7d',
        register: 'Se connecter',
    },
    tipsContent: { ...tipsFr, title: 'Conseils' },
    welcomePopupMessage: [
        "L'application DigiGreen collecte les informations de profil de votre compte Microsoft 365 (nom, prénom, photo) ainsi que des données d'usage des applications Outlook, Teams, Onedrive pour mesurer leur impact sur les émissions de CO2.",
        'Vous pourrez à tout moment supprimer votre compte via le menu disponible en cliquant sur votre photo de profil.',
    ],
    welcomeBack: {
        ...defaultFr.welcomeBack,
        emissionCard2: {
            ...defaultFr.welcomeBack.emissionCard2,
            description: 'des utilisateurs de DigiGreen dans l’entreprise.',
        },
    },
    accessDeniedPopupMessageWithLink: 'Pour obtenir une licence d’accès à DigiGreen renseignez le formulaire suivant : ',
    linkName: 'formulaire',
}

export default digigreenFr
