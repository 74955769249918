import { Fragment, createElement } from 'react'
import { DayOfWeek, toYearString } from '../../components/dateUtils'
import { EGoalType, Lang } from '../../models'
import { BadgeType } from '../../models/Badge'
import { Challenge } from '../../models/Challenge'
import { tipsEn } from './tipsContent'

const en: Lang = {
    code: 'en',
    locale: 'en-GB',
    display: 'eng',
    headerMenu: {
        info: 'Legal informations',
        help: {
            title: 'Help',
            link: 'https://lecko.notion.site/User-support-5a561b8c69eb43b882010e89625a8b90',
        },
        admin: 'Administration',
        animation: 'Animation',
        logout: 'Logout',
        deleteAccount: 'Delete my account',
        administratorAlert: 'Administrator mode.',
        animatorAlert: 'Animator mode.',
        switchToAnimatorAlert: 'Switch to Animator mode',
        switchToAdministratorAlert: 'Come back to Administrator mode',
        switchToStandardMode: 'Come back to classic mode',
        switchToDemoMode: 'Switch to demo mode',
    },
    loader: {
        authentication: 'Connecting...',
        loading: 'Loading...',
    },
    errors: {
        globalMessage: 'An error has occurred, please try again later',
    },
    authentication: {
        title: 'You have the ability to transform your organization!',
        description: ['Measure the CO2 emissions of your uses on Microsoft 365 to develop sustainable digital work practices'],
        buttonLabel: 'Sign in with Microsoft 365',
    },
    presentation: {
        title: 'You have the ability to transform your organization!',
        description: ['Measure the CO2 emissions of your uses on Microsoft 365 to develop sustainable digital work practices'],
    },
    piloteWaiting: {
        title: 'Please be patient, as the pîlote starts in just a few days !',
        description: '',
    },
    deleteAccountPopup: {
        title: 'Deletion of account',
        messages: [
            'By confirming, your Greet account will be deleted, your personal data will be erased.',
            'To finalize the operation, we ask you in the next screen to log out of your Microsoft 365 account.',
        ],
        confirmBtnText: 'Continue',
        cancelBtnText: 'Cancel',
    },
    administration: {
        title: 'Administration',
        subTitle: 'Cohort management',
        editDescription:
            'You must enter all the members of the cohort as desired. Modifying cohort members is for future activities only. The history with previously existing members is retained.',
        globalDescriptionSentence1: (minimumCohortMembersAuthorized: string) =>
            `A cohort requires ${minimumCohortMembersAuthorized} members at least to be created in order to respect GDPR commitments.`,
        globalDescriptionSentence2: (domains: string[]) =>
            `You must enter valid emails with ${domains.length > 1 ? `one of the following areas ${domains.join(',')}.` : `the following domain ${domains}.`}`,
        globalDescriptionSentence3: 'Teams based on cohorts will be visible to members from the interface from the next day.',
        deleteFilter: 'Remove filters',
        addCohort: 'Add a cohort',
        fieldName: {
            label: 'Label',
            name: 'Name',
            size: 'Size',
            lastUpdate: 'Last update',
        },
        secret: {
            invalid: 'The secret is invalid !',
            confirm: 'Confirm',
            cancel: 'Cancel',
            title: 'Enter the secret',
            description: 'The secret allows you to encrypt and decrypt emails.',
        },
        search: 'Search...',
    },
    home: {
        goals: {
            displayAllGoals: 'Show all goals',
            reduceDisplayedGoals: 'Reduce goals',
        },
        victory: {
            title: 'My victories',
            badges: {
                displayAllBadges: 'show all my victories',
                reduceDisplayedBadges: 'reduce all my victories',
                awardPeriod: (from: string, to: string) => `from ${from} to ${to}`,
                planetFriendly: {
                    title: 'Planet friendly',
                    reducedBy: 'Emissions reduced by',
                    comparedToLastYear: (decreaseValue: number, lastYear: string) => `${decreaseValue}% compared to ${lastYear}`,
                },
                drive: {
                    title: 'A bit of cleaning',
                    reducedBy: '',
                    comparedToLastYear: (removedDataMB: number) => `${removedDataMB} MB deleted in your OneDrive`,
                },
                mail: {
                    title: 'Our emails to the diet ',
                    reducedBy: '',
                    comparedToLastYear: (maxAttachementMB: number) => `One week without sending email with attachment larger than ${maxAttachementMB} MB`,
                },
                digitalCleanupDay: {
                    title: 'Digital cleanup Day',
                    reducedBy: '',
                    comparedToLastYear: (decreaseValue: number) => `${decreaseValue} MB cleaned`,
                },
                internAttachment: {
                    title: 'Stop internal attachments',
                    reducedBy: '',
                    comparedToLastYear: () => 'No attachments sent internally this week',
                },
                mailShot: {
                    title: 'No mailshot',
                    reducedBy: '',
                    comparedToLastYear: (mailShotGoal: number) => `Number of recipients per email limited to ${mailShotGoal} this week`,
                },
                imageHunt: {
                    title: 'Tidy Up Your Photos!',
                    reducedBy: '',
                    comparedToLastYear: (deletedImagesVolume: number) => `${deletedImagesVolume} MB of deleted images on your personal OneDrive `,
                },
                videoHunt: {
                    title: 'Videos spring cleaning',
                    reducedBy: '',
                    comparedToLastYear: (deletedVideosVolume: number) => `${deletedVideosVolume} MB of deleted videos on your personal OneDrive `,
                },
            },
            mateVictoriesTitle: 'Champions of the moment',
            mateVictory: 'won the badge',
        },
        blog: {
            title: 'Blog',
            readMore: 'Read more',
        },
    },
    goal: {
        typeInfo: {
            carbonFootPrint: {
                title: 'Reduce your CO2 emissions',
                theme: 'Environment',
            },
            infobesity: {
                title: 'Control infobesity',
                theme: 'Operational efficiency',
            },
            disconnectionTime: {
                title: 'Ensure disconnection times',
                theme: 'Well being at work',
            },
        },
        home: {
            addGoal: 'Add',
            addGroupGoal: 'Add a goal for a group',
            see: 'See details',
            precision: 'compared to the previous 30 days',
            groupProgress: 'Group progress :',
            individualProgress: 'Your progress',
            addindividualIndicators: 'Add my indicators',
            addindividualIndicatorsDescription: 'Track your own progress with your indicators',
        },
        individual: {
            button: {
                title: 'Me',
                addIndicators: 'Add your indicators',
                qvt: 'My QWL indicators',
            },
            planetFriendlyBadge: {
                title: 'Reducing CO2 emissions',
                description: `Each month get your badge by reducing your emissions
                 by 4% for the month compared to last year.`,
                seeAllBadges: 'See my previous badges',
                noBadge: `Each month, get your Planet friendly badge
                by reducing your emissions by 4% for the month
                compared to last year.`,
            },
            addIndicatorsPopup: {
                title: 'Add a personal goal',
                description:
                    'This view gathers your indicators following the same model as the collective objective. ' +
                    'You will be able to monitor your progress and benefit from individualised support. ' +
                    'By joining the process, you will be informed of new challenges launched by the facilitator of ' +
                    'the entities of which you are members.',
                descriptionSupp: 'You are the only one to see your individual indicators. You can delete your account at any time.',
                add: 'Add',
                cancel: 'Cancel',
            },
            deleteFolderProposal: {
                title: 'OneDrive Cleaning',
                description: function (lastUpdateDateTime: string, nbChildren: number, size: string, year: string, carbonFootprint: string): string {
                    return `You have a folder unchanged since ${lastUpdateDateTime} containing ${nbChildren} file${
                        nbChildren > 1 ? 's' : ''
                    } for a total volume of ${size}. Removing it would reduce your emissions by ${year} of ${carbonFootprint}.`
                },
                goToFolder: 'Open in OneDrive',
                next: 'Propose me another folder to clean',
                noMoreFolders: function (monthLimitFilter: number): string {
                    return `You have no inactive folder for ${monthLimitFilter} month${monthLimitFilter > 1 ? 's' : ''}`
                },
                popup: {
                    description: 'Did you delete your folder ?',
                    yes: 'Yes',
                    no: 'No',
                },
            },
        },
        collective: {
            button: {
                title: 'My groups',
            },
            presentation: {
                title: 'Team goals',
                noDescription: 'Define the team’s objective',
                save: 'Save',
            },
        },
        carbonFootprint: {
            title: 'Detail of emissions',
            groupetitle: 'Detail of emissions',
            measuredDataMissing: 'Missing data',
            correspondence: 'Related to the following usages :',
            emailBrick: {
                comparedToLastYear: (lastYear: string) => `compared to ${lastYear}`,
                usage: {
                    mail: 'email',
                    mails: 'emails',
                    attachment: 'attachment sent',
                    attachments: 'attachments sent',
                    receipt: 'recipient by email',
                    receipts: 'average recipient by email',
                    volumeAttachment: 'of attachments sent',
                },
            },
            oneDriveBrick: {
                comparedToLastYear: (lastYear: string) => `compared to ${lastYear}`,
                usage: {
                    file: 'Number of stored file',
                    files: 'Number of stored files ',
                    volumeFiles: 'Volume of stored files',
                    versionFiles: 'Number of cumulative versions',
                    versionVolumeFiles: 'Cumulative version volume',
                },
            },
            teamsBrick: {
                comparedToLastYear: (lastYear: string) => `compared to ${lastYear}`,
                usage: {
                    activity: 'Number of channel activity',
                    activities: 'Number of channel activities',
                    file: 'Number of stored file',
                    files: 'Number of stored files',
                    volumeFiles: 'Volume of stored files',
                },
            },
            copiloteBrick: {
                comparedToLastYear: (days: string) => `compared with the previous ${days} days `,
                usage: {
                    transcription: 'Number of meeting transcripts',
                    transcriptions: 'Number of meeting transcripts',
                },
            },
            evolution: {
                details: 'emitted with the sending of emails, the use of OneDrive and Teams',
                comparedTo: 'compared to the previous 30 days',
            },
        },
        qvt: {
            measuredDataMissing: 'Missing data',
            evolution: {
                title: 'The place of meetings in my daily life',
                details: 'spent in meetings',
                comparedTo: 'compared to the previous 30 days',
                inMeetingTime: 'In meeting',
                mobilizedTime: 'Mobilized time',
                tooltipLabel: (hour, minute, startDate, endDate, inMeeting) => [
                    `${hour > 0 ? hour + ' h ' : 'Not '}${minute > 0 ? minute + ' min ' : ''}${inMeeting ? 'in meeting' : 'mobilized'} from ${startDate}`,
                    `to ${endDate} included`,
                ],
                meetingLegend:
                    'The meeting time compresses the available time to focus on activities. Use the meeting wisely and be mindful of the time requested from your colleagues.',
            },
            meetingEvolution: {
                title: 'Evolution of meeting time',
                details: 'spent in meetings',
                comparedTo: 'compared to the previous 30 days',
                inMeetingTime: 'Participant',
                mobilizedTime: 'Organizer',
                tooltipLabel: (hour, minute, startDate, endDate, inMeeting) => [
                    `${hour > 0 ? hour + ' h ' : 'Not '}${minute > 0 ? minute + ' min ' : ''}${inMeeting ? 'in meeting' : 'mobilized'} from ${startDate}`,
                    `to ${endDate} included`,
                ],
                tooltipLabelUserSpentTime: (hour, minute, startDate, endDate, inMeeting) => [
                    `${hour > 0 ? hour + ' h ' : 'Not '}${minute > 0 ? minute + ' min ' : ''}${inMeeting ? 'in meeting' : ' in organized meeting'} from ${startDate}`,
                    `to ${endDate} included`,
                ],
                info: 'Indicates cumulative meeting times from Monday to Friday. Only meetings organized or accepted are taken into account. Overlapping meeting times are deducted from the total.',
                meetingLegend: 'Too many meetings squeeze the time left to concentrate on core tasks.'
            },
            meetingEvolutionCount: {
                title: 'Percentage of meetings facilitating a transition period',
                info: 'Number of meetings with scheduled durations other than full hours and half-hours, divided by the total number of meetings over the last 30 days. A meeting scheduled for 50 min or 1h15, for example, will be considered as “facilitating a transition time”.',
                meetingLegend:
                    "Reducing meetings by a few minutes gives everyone a breathing space before the next meeting. This transition time is essential to everyone's well-being and efficiency.",
            },
            survey: {
                question: "Was last week's meeting load sustainable over time ?",
                description: 'Collecting feedback allows you to calibrate the objectives of optimal meeting times',
                errorMessage: 'An error occurred, please try again later',
                successMessage: 'Your response has been recorded',
            },
            emailSendingRange: {
                title: 'Email sending range',
                tooltip: 'Day off',
                legend: {
                    text1: 'Less than 10 email sent during the day',
                    text2: '10 to 30 email sent during the day',
                    text3: 'More than 30 email sent during the day',
                    microRange: 'Less than 1 interaction',
                    smallRange: '1 to 3 interactions',
                    mediumRange: '3 to 6 interactions',
                    largeRange: '6 to 9 interactions',
                    heightRange: 'More than 9 interactions',
                },
                slotsTooltip: {
                    oneSlot: (slot: number, from: string, to: string) => `${slot} interaction${slot > 1 ? 's' : ''} from ${from} to ${to}`,
                    averageSlot: (slot: number, from: string, to: string) => `${slot} interaction${slot > 1 ? 's' : ''} from ${from} to ${to}`,
                },
                button1: '3 weeks',
                button2: '3 months',
                button3: '1 year',
                description1:
                    'This chronogram of e-mails sent maps interaction times. The possibility of working remotely tends to blur the boundaries of working time. This ease of personal organisation must not be at the expense of the recovery time our brains need for our personal equilibrium',
                description2: 'It is preferable to encourage exchanges during normal working hours for the long-term well-being of everyone',
            },
            meetingBreathingTime: {
                title: 'Distribution of meetings including breathing time',
                buttonWeek: '1 week',
                buttonMonth: '1 month',
                buttonQuarter: '1 quarter',
                buttonYear: '1 year',
                legend1: 'With breathing',
                legend2: 'Without breathing',
                lessThan: 'less than',
                to: 'to',
                tooltipMessage: (nbMeeting: number, interval: string) => `${nbMeeting} metting${nbMeeting > 1 ? 's' : ''} of ${interval}`,
                moreThan: 'more than',
                description:
                    "Reducing your meetings by a few minutes gives everyone some breathing time before the next meeting. These break times are necessary for everyone's well-being and efficiency.",
            },
            relationshipPositioning: {
                title: 'Position yourself in relation to my colleagues',
                description:
                    'These people have work contexts similar to yours. This allows you to situate yourself and better understand those around you. These graphs highlight your margins of progress or the importance of involving those around you to reduce the culture of overwhelm.',
                weeklyMeetingTime: {
                    title: 'Distribution of relatives by weekly meeting time',
                    info: 'Displays the breakdown of the 20 people with whom you exchange most by e-mail and in meetings, based on average weekly time over the last 4 weeks.',
                    description: {
                        title: 'Up to you !',
                        content: 'Review the role of meetings to reduce their use',
                    },
                    tooltip: function (nbUser: number, from: number, to: number): string {
                        return `${nbUser} ${nbUser > 1 ? 'users' : 'user'} spend ${from} to ${to} hours in meetings per week`
                    },
                    legend: 'You',
                },
                mobilizedMeetingTime: {
                    title: 'Distribution of relatives by time spent in meetings',
                    description: {
                        title: 'Up to you !',
                        content: 'Reduce the number of participants and duration of meetings',
                    },
                    tooltip: function (nbUser: number, from: number, to: number): string {
                        return `${nbUser} ${nbUser > 1 ? 'users' : 'user'} mobilized ${from} to ${to} hours of meeting per week`
                    },
                    legend: 'You',
                },
                wellBeingScore: {
                    title: 'Distribution of relatives by well-being score',
                    description: {
                        title: 'Up to you !',
                        content: 'Configure meetings by default to a 45 min format rather than an hour',
                    },
                    tooltip: function (nbUser: number): string {
                        return `${nbUser} ${nbUser > 1 ? 'relatives' : 'relative'}`
                    },
                    legend: 'You',
                },
                checkDescription:
                    'The list of closest colleagues is established by counting email exchanges over the last quarter. “Anonymous colleague” refers to a colleague who does not use Greet or who has not agreed to have his or her name displayed.',
                checkDescriptionTip: 'To view their names, agree to be visible in the list of your colleagues.',
                footer: {
                    active: {
                        description:
                            "The list of closest colleagues is established by counting email exchanges over the last quarter. “Anonymous colleague” refers to a colleague who does not use Greet or who has not agreed to have his or her name displayed. You access this list because you have agreed to be visible in your colleague's list.",
                        action: 'Hide your name in the list of your close colleagues.',
                        title: 'Your 20 closest colleagues:',
                        noColleague: 'None of your close colleagues have yet agreed to be visible',
                    },
                    inactive: {
                        description: 'The list of closest colleagues is established by counting email exchanges over the last quarter.',
                        action: "Pour consulter leurs noms, acceptez d'être visible dans la liste de vos collègues.",
                    },
                },
            },
            mobilizeTeam: 'Le temps mobilisés auprès de mes collègues',
            dailyMobilizeTeamMeetingEvolution: {
                title: 'Time taken up by organised meetings',
                description: {
                    title: 'Up to you',
                    text: 'Start by taking action on the meetings you organize',
                },
            },
            mobilizeTeamMeeting: {
                information: 'Shortening meetings by a few minutes gives everyone a bit of breathing space before the next meeting. These breaks are essential to the well-being',
                text: 'hours mobilized over the last 30 days',
            },
            meetingAttendeesDistribution: {
                title: 'Number of meetings by number of participants',
                information: 'Reducing the number of participants increases efficiency and frees up time.',
                legend: "Breakdown of the number of meetings you've organized over the last 30 days, by number of participants (including yourself).",
                chartBarTooltip: function (nbMeetings: number, nbAttendees): string {
                    return `${nbMeetings} ${nbMeetings > 1 ? 'meetings' : 'meeting'} with ${nbAttendees} attendees`
                },
            },
            communicationModesDistribution: {
                title: 'Adopting the right mode of communication',
                subtitle: (dateFrom, dateTo) => `Breakdown of communication methods used from ${dateFrom} to ${dateTo}`,
                charts: {
                    titles: {
                        closestPeople: 'With the 20 people closest to you',
                        allContacts: 'With all its contacts',
                    },
                    legend: {
                        teamsChannels: 'Teams Channels',
                        chat: 'Chat',
                        email: 'Email',
                    },
                    description: {
                        title: 'Up to you !',
                        description:
                            'Create Teams (or channels) to exchange information with your daily contacts, classifying information by subject and limiting interruptions during the day.',
                    },
                    tip: {
                        title: createElement('strong', null, 'Are you using the right channels? Each medium has its own purpose'),
                        modes: {
                            chat: createElement(Fragment, null, createElement('strong', null, 'Chat :'), ' informal, urgency, micro-tasks'),
                            teamsChannels: createElement(
                                Fragment,
                                null,
                                createElement('strong', null, 'Teams channels :'),
                                ' thematic activities, asynchronous discussions, explicit collaboration on an activity or project',
                            ),
                            email: createElement(
                                Fragment,
                                null,
                                createElement('strong', null, 'Email :'),
                                ' more formal exchange, a universal medium for communicating with external parties, a channel for making contacts or for one-off exchanges.',
                            ),
                        },
                        message: '« Incessant solicitations » are irresistible to our brains, like sugar, an addiction that undermines our collective efficiency and well-being.',
                    },
                },
            },
            teamsConfiguration: {
                title: 'Configuring Teams for greater efficiency',
                tip: 'For greater legibility, we suggest pinning the most important Teams, leaving inactive Teams or archiving your old Teams.',
                tabs: {
                    oneMonth: '1 month',
                    oneQuarter: '1 quarter',
                    oneYear: '1 year',
                    teamsToPin: 'Teams to pin',
                    teamsToLeave: 'Teams to leave',
                    teamsToArchive: 'Teams to archive',
                },
                chartLegend: {
                    activeTeams: 'active Teams',
                    inactiveTeams: 'inactive Teams',
                },
                teams: {
                    toPin: {
                        noTeams: 'No Teams to pin.',
                        tip: 'Pinning Teams dealing with your current priority activities allows you to prioritize your attention.',
                    },
                    toLeave: {
                        noTeams: 'No Teams to leave.',
                        caption: 'These Teams have been inactive for more than 6 months.',
                        tip: 'Quitting inactive Teams makes the Teams ribbon lighter and saves time when you scan them.',
                    },
                    toArchive: {
                        noTeams: 'No Teams to archive.',
                        caption: 'These Teams, which you own, have been inactive for more than 6 months.',
                        tip: 'Once the activity is over, it is best to isolate the documents to be kept and delete the working versions. In the majority of cases, it is recommended that you delete Teams.',
                    },
                    suggest: 'Suggest other Teams',
                },
            },
        },
        carbonCumul: {
            titleIndividuel: 'My trajectory',
            leftTextIndividuel: 'Reduce CO2 emissions by 4% per year to comply with the Paris Agreement.',
            titleGroup: 'Group emissions',
            leftTextGroup: 'Reduce CO2 emissions by 4% per year to comply with the Paris Agreement.',
            carbonEmissions: (goalType: EGoalType) => {
                switch (goalType) {
                    case EGoalType.COLLECTIVE:
                        return `Average CO2 emissions per user since January 1st, ${new Date().getFullYear()} :`
                    case EGoalType.INDIVIDUAL:
                        return `My cumulative emissions since January 1st, ${new Date().getFullYear()} :`
                    default:
                        return ''
                }
            },
            toolTip: 'over the last 30 days',
            annee: 'Year',
            goal: 'Goal',
            decile: {
                title: {
                    myPosition: 'My position :',
                    info: 'A decile represents one tenth of the total population. There are therefore 10 deciles',
                },
                position(firstQuintil, position) {
                    return `You've emitted ${firstQuintil ? 'less' : 'more'} than ${position}% of Greet users in the company.`
                },
            },
        },
        benchMark: {
            title: 'References',
            description: 'My cumulative emissions since January 1st correspond ',
            hour: 'hour',
            hours: 'hours',
            measureAgainst: 'of 10W light bulb on',
        },
        challenge: {
            title: 'Challenge of the week',
            noChallenge: {
                simple: 'No scheduled challenge',
                animator: 'To make your group progress in its objective add a challenge',
            },
            beforeChallengeStartText: 'before the challenge starts',
            digitalCleanupDay: {
                noDataTitle: 'Make your digital workspace lighter!',
                noDataText: 'Remember to clean up your Onedrive and mailbox.',
                mails: 'deleted emails',
                files: 'deleted files',
                after: {
                    title: 'Clean up your mailbox and OneDrive space',
                    titleGroup: 'achievement',
                    groupName: 'Group ',
                    titleCompany: 'achievement',
                    companyName: 'Company ',
                },
            },
            label: (badgeType: BadgeType) => {
                switch (badgeType) {
                    case BadgeType.PLANET_FRIENDLY:
                        return 'Planet friendly'
                    case BadgeType.MAIL:
                        return 'Our emails to the diet'
                    case BadgeType.DRIVE:
                        return 'A bit of cleaning'
                    case BadgeType.DIGITAL_CLEANUP_DAY:
                        return 'Digital Cleanup'
                    case BadgeType.INTERN_ATTACHMENT:
                        return 'Stop Internal Attachments'
                    case BadgeType.MAILSHOT:
                        return 'No mailshot'
                    case BadgeType.IMAGE_HUNT:
                        return 'Tidy Up Your Photos!'
                    case BadgeType.VIDEO_HUNT:
                        return 'Videos spring cleaning'
                }
            },
            description: (challenge: Challenge) => {
                switch (challenge.badgeType) {
                    case BadgeType.MAIL:
                        return `Limit attachments to ${challenge.mailGoalSize} MB`
                    case BadgeType.DRIVE:
                        return `Delete ${challenge.driveGoalSize} MB in your personal OneDrive`
                    case BadgeType.DIGITAL_CLEANUP_DAY:
                        return 'Clean up your mailbox and your OneDrive space'
                    case BadgeType.MAILSHOT:
                        return `Limit the maximum number of recipients to ${challenge.mailShotGoalSize} per email this week`
                    case BadgeType.INTERN_ATTACHMENT:
                        return "Don't send any attachments internally this week"
                    case BadgeType.IMAGE_HUNT:
                        return `Delete ${challenge.imageHuntGoalSize} MB of images from your personal OneDrive this week`
                    case BadgeType.VIDEO_HUNT:
                        return `Delete ${challenge.videosHuntGoalSize} MB of videos from your personal OneDrive this week`
                    default:
                        return ''
                }
            },
            badgeDescription: (badgeType: BadgeType) => {
                switch (badgeType) {
                    case BadgeType.MAIL:
                        return 'Badges related to the reduction in sending emails with attachments'
                    case BadgeType.DRIVE:
                        return 'Badges related to file deletion in your personal OneDrive'
                    case BadgeType.PLANET_FRIENDLY:
                        return 'Every month, get your Planet friendly badge by reducing your emissions by 4% over the month compared to last year'
                    case BadgeType.DIGITAL_CLEANUP_DAY:
                        return 'Badges related to digital cleanup day'
                    case BadgeType.INTERN_ATTACHMENT:
                        return 'Badges related to the reduction of attachments sent internally'
                    case BadgeType.MAILSHOT:
                        return 'Badges related to direct email reduction'
                    case BadgeType.IMAGE_HUNT:
                        return 'Badges related to reducing the image volume on your personal OneDrive'
                    case BadgeType.VIDEO_HUNT:
                        return 'Badges related to reducing the video volume on your personal OneDrive'
                    default:
                        return ''
                }
            },
            ongoingChallenge: 'ongoing challenge',
        },
        setupChallenge: {
            title: 'Challenges configuration',
            save: 'Save',
            scheduled: (nb: number) => `Scheduled challenge${nb !== 1 ? 's' : ''}`,
            to: 'to',
            form: {
                title: 'Schedule the challenge',
                labels: {
                    period: 'Period',
                    category: 'Category',
                },
                sizeDrive: 'Documents volume to delete in drive (MB)',
                sizeMail: 'Maximum attachments size (MB)',
                sizeMailDigitalCleanupDay: 'Attachments size volume to delete (MB)',
                sizeMailShot: 'Maximum number of recipients per email',
                sizeImageHunt: 'Images volume to delete in drive (MB)',
                sizeVideoHunt: 'Videos volume to delete in drive (MB)',
            },
        },
        cohort: {
            title: 'They are mobilized',
            joinCohort: 'Join the process by adding your personal indicators',
            noUserCohortMessage: 'Because we go further together, mobilize your colleagues on the team objective!',
        },
        tips: {
            title: 'Tips',
            readMore: 'Go to the resource center',
        },
        coach: {
            form: {
                description: {
                    part1: 'Description (part 1)',
                    part2: 'Description (part 2)',
                },
                coachContent: {
                    description: 'Coaches monitoring',
                    recommendedSize: '(Recommended size of pictures: 369 x 239)',
                    firstname: 'Firstname',
                    calendarURL: 'Calendar URL',
                    base64Picture: 'Base64 picture',
                    addPicture: 'Choose a picture',
                    addCoach: 'Add a coach',
                    removeCoach: 'Delete',
                },
                display: {
                    description: 'Choice of coach number',
                    oneCoach: 'One coach',
                    twoCoach: 'Two coaches',
                },
                save: 'Save',
                cancel: 'Cancel',
            },
            textBtn: 'Make an appointment with ',
        },
        weeklyCleaning: {
            title: 'Your latest cleanup',
            cleanPeriod: (from: string, to: string) => `${from} to ${to}`,
            description: 'Clean up your mailbox and OneDrive space',
            noCleaning: 'Nothing was deleted this week',
            emailDeleted: 'of deleted emails',
            driveDeleted: 'of removed files',
        },
    },
    tipsContent: tipsEn,
    webinar: {
        title: 'Webinaire',
        startAt: 'June 12 at 2pm',
        contentTitle: 'How to organize the sharing spaces so that everyone can easily find the information',
        description:
            'Adopting simple usage conventions makes it easier to organize and find information. ' +
            'Everyone saves time and remains confident in their ability to find useful information.',
        registerationUrl: '#',
        register: 'Register',
        addToAgenda: 'Add to calendar',
        form: {
            eventDate: 'Date',
            content: 'Content',
            description: 'Description',
            registerationUrl: 'Event url',
            save: 'Save',
            cancel: 'Cancel',
        },
    },
    unitedNation: {
        title: 'Paris Agreement - UN Framework Convention on Climate Change',
        link: 'https://www.un.org/en/climatechange/paris-agreement',
    },
    date: {
        range: (from: string, to: string) => `${from} to ${to}`,
    },
    daysAbbreviation: 'days',
    dayFirstLetter: 'd',
    components: {
        select: {
            selectCohortDefaultLabel: 'My groups',
            defaultNoChoicesLabel: 'None of your groups are following this goal yet',
            AddYourOwnIndicatorsMsg: 'Start following this goal by adding your indicators',
        },
    },
    thanksForInterest: 'Thank you for your interest !',
    accessDeniedPopupMessage: (supportMail) =>
        `Access to Greet is currently restricted to pilot participants. You can contact ${supportMail} who is leading the pilot with any questions. Stay tuned !`,
    accessDeniedPopupMessageWithLink: 'Access to Greet is currently restricted to pilot participants. For any question thank you for going to',
    linkName: 'form',
    welcomePopupMessage: [
        'Greet application collects profile information from your Microsoft 365 account (last name, first name, photo) as well as usage data from Outlook, Teams, Onedrive, Sharepoint applications to measure their impact on CO2 emissions.',
        'You can delete your account at any time via the menu available by clicking on your profile picture.',
    ],
    welcomePopup: {
        title: 'Informations',
        confirmBtn: 'I understood',
    },
    legalInformations: {
        title: 'Legal information',
        description: (supportMail) =>
            `The general terms and conditions of use and our commitment to protecting your personal data are available below. For further information, please contact : ${supportMail}`,
        cgu: {
            title: 'General terms of use',
            link: 'Download general terms of use (pdf)',
        },
        charter: {
            title: 'Personal data use charter',
            link: 'Download personal data use charter (pdf)',
        },
    },
    animationChallenge: {
        title: 'Animation',
        tableTitle: 'Challenge results',
        tableHeaders: {
            utilisateur: 'User',
            email: 'Email',
            cohortId: 'Group',
            challenge: 'Challenge',
            result: 'Result',
            dates: 'Dates',
            gagne: 'Earned',
        },
        filters: {
            search: 'Search...',
            cohort: 'cohort...',
            allCohort: 'All cohorts',
            challenge: 'Challenge...',
            allChallenges: 'All challenges',
            deleteFilters: 'Delete filters',
            export: 'Export (csv)',
        },
    },
    challengeEndPopup: {
        text(earned: boolean, cohortName: string, period: { from: string; to: string }) {
            const common = [createElement('strong', null, cohortName), ' team challenge organized from ', createElement('strong', null, `${period.from} to ${period.to}`)]

            if (earned) {
                return {
                    title: 'Congratulations !',
                    description: createElement(Fragment, null, ...['You have successfully completed the last ', ...common]),
                }
            }

            return {
                title: "We're counting on you for the next challenge !",
                description: createElement(Fragment, null, ...["You didn't make it to the end of the ", ...common]),
            }
        },
        button: 'Ok',
    },
    footer: {
        releaseNote: {
            label: 'Release note',
            link: 'https://lecko.notion.site/Change-log-73a06879d9f0467a8d8697c804b0ff94',
        },
        bugReport: {
            label: 'Report a problem',
            title: 'Report a problem or astonishment',
        },
        cache: {
            label: 'Clear cache',
            infoBulle: 'Deletes information stored by the browser',
        },
        copyright: '© 2023 Greet by lecko - All rights reserved',
    },
    badgeMonitor: {
        title: 'My badges',
        resultForOneBadge: 'badge won',
        resultForServeralBadges: 'badges won',
        seeAll: 'See all',
        popup: {
            awardPeriod: (from: string, to: string) => `from ${from} to ${to}`,
            planetFriendly: (decreaseValue: number, lastYear: string) => `Emissions reduced by ${decreaseValue}% compared to ${lastYear}`,
            drive: (removedDataMB: number) => `${removedDataMB} MB deleted in your OneDrive`,
            mail: (maxAttachementMB: number) => `No email sent with attachment larger than ${maxAttachementMB} MB`,
            digitalCleanupDay: (removedDataMB: number) => `${removedDataMB} MB cleaned`,
            internAttachment: 'No attachments sent internally',
            mailShot: (mailShotGoal: number) => `Number of recipients per email limited to ${mailShotGoal}`,
            imageHunt: (deletedImagesVolume: number) => `${deletedImagesVolume} MB of deleted images on your personal OneDrive`,
            videoHunt: (deletedVideosVolume: number) => `${deletedVideosVolume} MB of deleted videos on your personal OneDrive`,
        },
        noBadge: "You don't have any badge for this challenge yet",
        seeMore: 'See more',
        seeLess: 'See less',
    },
    personalScoring: {
        pointsDescription: {
            carbonFootprint: 'Commitment:',
            qvt: 'Digital QVCT score',
        },
        positionDescription: {
            carbonFootprint: 'Your ranking:',
            qvt: 'Preserving cognitive resources in the face of hyperconnection and improving efficiency',
        },
        maxPositonDescription: 'users',
        rankUnit: 'places',
        rankEqual: 'No changes compared with last month',
        rankUp: (rankDiff: number) => `${rankDiff} point${rankDiff > 1 ? 's' : ''} lost compared with last month`,
        rankDown: (rankDiff: number) => `${rankDiff} point${rankDiff > 1 ? 's' : ''} earned compared with last month`,
        positionDecorator: (position: number) => {
            if (position === 1) {
                return 'st'
            } else if (position === 2) {
                return 'nd'
            } else if (position === 3) {
                return 'rd'
            }
            return 'th'
        },
        tooltip: {
            carbonFootprint:
                'Gain points by completing challenges, cleaning your spaces or connecting regularly! Details of the allocation rules can be found in the help section.',
            qvt: 'Gain points by completing reducing email sent outside of working hours, adding space between meetings and reducing email sent in meetings.',
        },
    },
    welcomeBack: {
        emissionTitle: 'Emissions',
        emissionCard1: {
            title: `Your cumulative emissions in ${toYearString(new Date())} are`,
        },
        emissionCard2: {
            title: "You've emitted",
            description: 'of Greet users in the company.',
        },
        emissionCard3: {
            description: 'Your job and your seniority in the organization have as much an impact on your emissions as your habits.',
        },

        evolutionTitle: 'Evolution',
        evolutionCoparaison: (evolution) => (evolution < 0 ? 'up' : 'down'),
        evolutionCard1: {
            title: 'Your monthly emissions are',
            description: 'on last year',
        },
        evolutionCard2: {
            description: 'Everyone needs to adapt their practices to reduce emissions by 4% a year in line with the Paris agreement.',
        },
        evolutionCard3: {
            descriptionParams: (users) => `Join the ${users} users who reduce their emissions by at least 4% / year.`,
        },

        engagementTitle: 'Engagement',
        engagementCard1: {
            descriptionParams: () => "Thanks to your clean-up actions and the challenges you've won, you've earned",
        },
        engagementCard2: {
            title: 'Your level of commitment places you in the',
            descriptionParams: (maxRank) => `out of ${maxRank} users.`,
        },
        engagementCard3: {
            description: 'Introducing new habits into your routines is the key to behavioral change.',
        },
        footerText: 'With Greet, every effort is made visible, and your progress is rewarded !',
        buttonLabel: "It's up to you !",
        checkboxPopUp: "Don't show pop up at the next connection",
        lessThan: 'less than',
        moreThan: 'more than',
    },
    clipboardCopy: {
        success: 'Copied',
        error: 'An error occurred during copying',
    },
    worloadEvaluation: {
        title: (debut, fin) => `Week from ${debut} to ${fin}`,
        subTitle: 'This week…',
        question1: 'My workload was…',
        question2: 'My work/life balance was…',
        question3: 'My pleasure at work…',
        answersInformations: {
            cry: 'Bad',
            disappointed: 'Pretty bad',
            medium: 'Neither good nor bad',
            smile: 'Good',
            happy: 'Excellent',
        },
        notAnswerdEvaluation: "You haven't evaluated your week yet",
        modifyAnswerdEvaluation: 'You can change your evaluation for the current week',
        validateToAnswer: 'Validate to confirm your new evaluation',
        answerdEvaluation: 'Your validation has been processed',
        submitButton: 'Validate',
        closeButton: 'Close',
        accessToIndicatorsButton: 'Go to indicators',
        actionsMessage: 'Adapter ses pratiques pour préserver ses ressources cognitives et gagner en efficacité !',
    },
    qvtManagerBoard: {
        title: "Evaluation of the team's workload",
        tabTitle: 'My team',
        answer: 'Answer',
        weekFormat: (from, to) => `Working days: ${from ?? ''} to ${to ?? ''}`,
        tableHeaders: {
            utilisateur: 'User',
            weekPrefix: 'W',
        },
        graph: {
            q1Label: 'Workload',
            q2Label: 'Work/life balance',
            q3Label: 'Satifaction',
        },
    },
    worloadEvaluationPersonalResult: {
        title: 'Workload experienced in previous weeks',
        lastWeeks: 'Last weeks…',
        weekFormat: 'Usual working range',
        weekFormatRange: (from, to, dayCount, hourStart, hoursEnd) => `From ${from} to ${to} (${dayCount} days) ${hourStart} - ${hoursEnd}`,
        graph: {
            q1Label: 'My workload was',
            q2Label: 'My work/life balance was',
            q3Label: 'My pleasure at work was',
        },
        legend: {
            text1: 'Individual and collective digital practices have an impact on your cognitive resources.',
            text2: 'The indicators below are there to help you preserve them.',
            textLink: 'More information',
        },
    },
    qvtFutureMeeting: {
        description: 'Estimated number of meetings over the next 7 days',
        comparaison: 'average',
    },
    volumeUnit: {
        bytes: 'bytes',
        megaBytes: 'MB',
        gigaBytes: 'GB',
    },
    daysOfWeek: [
        { label: 'Monday', value: DayOfWeek.MONDAY },
        { label: 'Tuesday', value: DayOfWeek.TUESDAY },
        { label: 'Wednesday', value: DayOfWeek.WEDNESDAY },
        { label: 'Thursday', value: DayOfWeek.THURSDAY },
        { label: 'Friday', value: DayOfWeek.FRIDAY },
        { label: 'Saturday', value: DayOfWeek.SATURDAY },
        { label: 'Sunday', value: DayOfWeek.SUNDAY },
    ],
}

export default en
