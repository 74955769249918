import classNames from 'classnames'
import { useMemo } from 'react'
import EmailSendingRange from '../components/EmailSendingRange'
import EvolutionWidget from '../components/EvolutionWidget'
import Title from '../components/Title'
import TeamsInterractions from '../components/carbonfootprint/TeamsUserInterractions'
import Coaching from '../components/coaching/Coaching'
import { substractDays, toLiteralIntervalParts } from '../components/dateUtils'
import IndividualUserWidget from '../components/individualUserWidget/IndividualUserWidget'
import QVTDailyMobilizeTeamMeetingEvolution from '../components/qvt/QVTDailyMobilizeTeamMeetingEvolution'
import QVTFutureMeeting from '../components/qvt/QVTFutureMeeting'
import QVTMeetingAttendeesDistribution from '../components/qvt/QVTMeetingAttendeesDistribution'
import QVTMobilizeTeamMeeting from '../components/qvt/QVTMobilizeTeamMeeting'
import QVTMonthlyMeetingCounts from '../components/qvt/QVTMonthlyMeetingCounts'
import QVTMonthlyMeetingEvolution from '../components/qvt/QVTMonthlyMeetingEvolution'
import QVTWeeklyRelationshipPositioningGraph from '../components/qvt/QVTWeeklyRelationshipPositioningGraph'
import { useStore, useUser } from '../contexts'
import { EGoal, Params } from '../models'
import useQVTIndividualGoalStyles from './QVTIndividualGoalStyle'
import { Redirect, useParams } from 'react-router-dom'
import useQVTAccess from '../services/useQVTAccess'

export default function QVTIndividualGoal() {
    const { lang } = useUser()
    const { langParam, customerParam } = useParams<Params>()
    const styles = useQVTIndividualGoalStyles()
    const {
        state: { userPayload },
    } = useStore()
    const qvtAccess = useQVTAccess()

    const calculationRange = useMemo(() => {
        if (userPayload?.calculationDate == null) {
            return ''
        }
        const calculationInterval = toLiteralIntervalParts(substractDays(userPayload?.calculationDate, 30), substractDays(userPayload?.calculationDate, 1), lang.locale)
        return lang.date.range(calculationInterval[0], calculationInterval[1])
    }, [userPayload, lang])

    if (userPayload == null) {
        return null
    }

    return (qvtAccess ? (
        <div className={styles.container}>
            <div className={styles.individualComponentContainer}>
                <IndividualUserWidget userPayload={userPayload} eGoal={EGoal.QVT} />
                <EvolutionWidget
                    period={calculationRange}
                    periodComputedCount={{
                        count: userPayload?.nbHoursInMeetings == null ? undefined : `${Math.round(userPayload?.nbHoursInMeetings)} h`,
                        description: lang.goal.qvt.evolution.details,
                    }}
                    periodComputedEvolution={Math.round(userPayload?.evolutionOfNbHoursInMeetings ?? 0)}
                    noEvolutionInfo={lang.goal.qvt.measuredDataMissing}
                    comparedToText={lang.goal.qvt.evolution.comparedTo}
                />
                <QVTFutureMeeting />
            </div>
            <div className={classNames([styles.individualDarkTitledComponentContainer, styles.individualDarkComponentContainer])}>
                <div>
                    <Title title={lang.goal.qvt.evolution.title} subTitle={calculationRange} />
                </div>
                <div className={styles.individualComponentContainer}>
                    <QVTMonthlyMeetingEvolution />
                    <QVTMonthlyMeetingCounts />
                    <QVTWeeklyRelationshipPositioningGraph />
                </div>
            </div>
            <div className={classNames([styles.individualDarkTitledComponentContainer, styles.individualDarkComponentContainer])}>
                <div>
                    <Title title={lang.goal.qvt.mobilizeTeam} subTitle={calculationRange} />
                </div>
                <div className={styles.individualComponentContainer}>
                    <QVTDailyMobilizeTeamMeetingEvolution />
                    <QVTMobilizeTeamMeeting />
                    <QVTMeetingAttendeesDistribution userPayload={userPayload} />
                </div>
            </div>

            <EmailSendingRange />
            <TeamsInterractions userPayload={userPayload} />
            <Coaching />
        </div> ) : ( <Redirect to={`/${langParam}/${customerParam}`} /> )
    )
}
